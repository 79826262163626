import React, { FC, memo, ReactNode, useEffect, useMemo } from "react"

import { ERequestStatus, IHandbook, IIndustrialObject, IStationInfo } from "@types"
import { createOptimizedContext, MiniStore, useHandbookAPI, useIndustrialObjectAPI, useStationListAPI } from "@utils"
import { FilterController } from "./_FilterController"

interface IEquipmentPageContext {
  filterController: FilterController

  handbook: IHandbook

  stationList: IStationInfo[]
  stationListStatus: ERequestStatus

  industrialObjectList: IIndustrialObject[]
}

const {
  Provider: DashboardProvider,
  useStateSelector: useDashboardPageSelector,
  useUpdate: useDashboardPageUpdate,
} = createOptimizedContext<IEquipmentPageContext>()

const DashboardProviderMemo = memo(DashboardProvider)

const DashboardPageContextProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const filterController = useMemo(() => new FilterController(), [])

  const { handbook } = useHandbookAPI()
  const { stationList, status: stationListStatus } = useStationListAPI()
  const { industrialObjectList } = useIndustrialObjectAPI()

  const state = useMemo(
    () => ({ filterController, handbook, stationList, stationListStatus, industrialObjectList }),
    [handbook, stationList, stationListStatus, industrialObjectList]
  )

  const store = useMemo(() => new MiniStore<IEquipmentPageContext>(state), [])

  useEffect(() => {
    store.update({ ...state })
  }, [state])

  return <DashboardProviderMemo store={store} children={children} />
}

export { DashboardPageContextProvider, useDashboardPageSelector, useDashboardPageUpdate }
