import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react"

import { EN, RU } from "@assets"
import { ELang, ILocale } from "@types"
import { TSetState } from "@types"
import { localeDTO } from "@utils"

interface ILocalizationContextValue {
  l: ILocale
  setLocale: TSetState<ELang>
}

interface ILocalizationContextProvider {
  children: React.ReactNode
}

const langs = {
  [ELang.RU]: RU,
  [ELang.EN]: EN,
}

const LocalizationContext = createContext({} as ILocalizationContextValue)
export const useLocalizationContext = (): ILocalizationContextValue => useContext(LocalizationContext)

export const LocalizationContextProvider: React.FC<ILocalizationContextProvider> = ({ children }) => {
  const isFirstRender = useRef(true)

  const storedLocale = localStorage.getItem("language")

  const [locale, setLocale] = useState((storedLocale as ELang) ?? ELang.EN)

  const l = useMemo((): ILocale => langs[locale] as ILocale, [locale])

  const lContextValue = useMemo((): ILocalizationContextValue => ({ l, setLocale }), [l])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    localStorage.setItem("language", locale)
    window.location.reload()
  }, [locale])

  useEffect(() => {
    localeDTO.l = l
  }, [l])

  return <LocalizationContext.Provider value={lContextValue}>{children}</LocalizationContext.Provider>
}
