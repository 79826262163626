import { ELayer } from "@types"

const MO_BOUNDS = {
  NW: { lat: 56.52019152799423, lng: 35.92443605610248 },
  SE: { lat: 54.784935685126996, lng: 39.33569093891498 },
}

const PODOLSK_BOUNDS = {
  NW: { lat: 55.53152216058785, lng: 37.23792477656063 },
  SE: { lat: 55.33009775851779, lng: 37.87238522577938 },
}

const ME_BOUNDS = {
  NW: {
    lat: 24.625232,
    lng: 54.204867,
  },
  SE: {
    lat: 24.348487,
    lng: 54.726153,
  },
}

const ZOOM_THRESHOLDS = {
  initial: 12,
  station: 15,
  object: 14,
}

const GRID_SIZE = {
  small: 300,
  large: 1_050,
}

const COLLIDING_LAYERS = [ELayer.COMPLEX, ELayer.AERIAL, ELayer.ABSORPTION]

export { MO_BOUNDS, PODOLSK_BOUNDS, ME_BOUNDS, ZOOM_THRESHOLDS, GRID_SIZE, COLLIDING_LAYERS }
