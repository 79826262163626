import React, { createContext, useContext, useState, useMemo, useEffect } from "react"
import { Loader } from "@googlemaps/js-api-loader"

import { useLocalizationContext } from "./_LocalizationContext"
import { MAPS_API_KEY } from "@constants"

interface IGoogleMapsContext {
  googleMaps: typeof google.maps | null
}

interface IGoogleMapsContextProvider {
  children: React.ReactNode
}

const GoogleMapsContext = createContext({} as IGoogleMapsContext)
export const useGoogleMapsContext = (): IGoogleMapsContext => useContext(GoogleMapsContext)

export const GoogleMapsContextProvider: React.FC<IGoogleMapsContextProvider> = ({ children }) => {
  const {
    l: { language },
  } = useLocalizationContext()
  const [googleMaps, setGoogleMaps] = useState<typeof google.maps | null>(null)

  const loader = useMemo(
    () =>
      new Loader({
        apiKey: MAPS_API_KEY,
        version: "weekly",
        language,
        libraries: ["visualization"],
      }),
    []
  )

  useEffect(() => {
    loader
      .load()
      .then((google) => {
        setGoogleMaps(google.maps)
      })
      .catch((e) => {
        console.log("Ошибка загрузки карт Google: ", e)
      })
  }, [])

  return <GoogleMapsContext.Provider value={{ googleMaps }}>{children}</GoogleMapsContext.Provider>
}
