import { CSSProperties } from "react"
import { createTheme, Theme, Components } from "@mui/material"
import { PaletteOptions } from "@mui/material/styles"
import "@mui/material/styles/createPalette"

import { components, palette, breakpoints } from "./config_variables"

// Декларация типов для кастомной стилизации
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    autoHeight: true
    indent: true
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: CSSProperties
    button1: CSSProperties
    button2: CSSProperties
    button3: CSSProperties
  }

  interface TypographyVariantsOptions {
    body3?: CSSProperties
    button1?: CSSProperties
    button2?: CSSProperties
    button3?: CSSProperties
  }

  interface PaletteColor {
    A50?: string
    A100?: string
    A200?: string
    A300?: string
    A400?: string
    A500?: string
    A600?: string
    A700?: string
    A800?: string
    A900?: string
    A1000?: string
  }
  interface SimplePaletteColorOptions {
    A50?: string
    A100?: string
    A200?: string
    A300?: string
    A400?: string
    A500?: string
    A600?: string
    A700?: string
    A800?: string
    A900?: string
    A1000?: string
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true
    button1: true
    button2: true
    button3: true
    h5: true
    h6: true
    overline: false
  }
}

/*
 * Кастомизация темы Material UI и его компонентов
 */
export const theme: Theme = createTheme({
  components: components as Components,
  palette: palette as PaletteOptions,
  breakpoints: breakpoints,
})
