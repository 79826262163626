import { EAll, EEmission, ELayer, IFilterController, IMapFilterValues, TFilterCb, TSetMapFilters } from "@types"
import { isEEmission, isELayer } from "@utils"

const defaultValues = {
  layers: [ELayer.COMPLEX, ELayer.STATIONS, ELayer.OBJECTS],
  emission: EEmission.CO2e,
  selectedElement: null,
}

export class FilterController implements IFilterController {
  private _filters: IMapFilterValues
  private subs: TFilterCb[] = []

  constructor(initialValues?: IMapFilterValues) {
    this._filters = initialValues ?? defaultValues
  }

  get filters(): IMapFilterValues {
    return this._filters
  }

  setFilters: TSetMapFilters = (newFilters: Partial<IMapFilterValues>) => {
    const updated = { ...this._filters, ...newFilters }

    if (JSON.stringify(this._filters) === JSON.stringify(updated)) return

    this._filters = updated

    this.subs.forEach((cb) => cb(this._filters))
  }

  hasPassedFilters = <T extends EEmission | ELayer | EAll>(value: T): boolean => {
    const { layers, emission } = this._filters

    if (isELayer(value)) return layers.includes(value)
    if (isEEmission(value)) return emission === value

    return false
  }

  onFiltersChange = (cb: TFilterCb): void => {
    if (!this.subs.includes(cb)) this.subs.push(cb)
  }
}
