import { palette } from "./_palette"

export const components = {
  //  Стилизация DataGrid и ее элементов //
  MuiDataGrid: {
    styleOverrides: {
      root: {
        "border": "none",

        "a": {
          textDecoration: "none",
          color: palette.secondary.main,
        },

        "& .Mui-selected": {
          //   "background": `${palette.primary.dark} !important`,
          "& .MuiAlert-icon, p": {
            color: palette.secondary.main,
          },
        },

        "& .MuiDataGrid-cell": {
          fontSize: "14px",
          fontWeight: 400,
        },

        "& .MuiDataGrid-row--link": {
          cursor: "pointer",
        },

        "& .Mui-systems-custom": {
          "minHeight": "48px !important",
          "maxHeight": "48px !important",

          "& .MuiDataGrid-cell": {
            minHeight: "48px !important",
            maxHeight: "48px !important",
            fontSize: "16px",
            borderBottom: "none",
          },

          "&.Mui-selected": {
            "& .MuiSvgIcon-root": {
              color: palette.secondary.main,
            },
          },
        },

        "& .MuiDataGrid-checkboxInput": {
          color: `${palette.secondary.main} !important`,
        },

        "& .MuiDataGrid-menuIconButton": {
          display: "none",
        },

        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: 0,
          fontSize: "16px",
          fontWeight: 500,
        },

        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },

        "& .MuiDataGrid-columnHeaders *:focus-within, .MuiDataGrid-columnHeaders *:focus": {
          outline: "none !important",
        },

        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },

        "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
          fontSize: "14px",
          fontWeight: 400,
        },

        "& .MuiDataGrid-virtualScroller": {
          background: "transparent",
          boxShadow: "inset 1px 0px 0px #2F2F2F",
          direction: "ltr",
          scrollbarColor: "#d4aa70",
          scrollbarWidth: "thin",
        },
        "& .editable .MuiDataGrid-cellContent, .editable input": {
          height: "36px !important",
          width: "100%",
          fontSize: "14px",
          lineHeight: "24px",
          padding: "6px 12px",
          border: `1px solid ${palette.secondary.light}`,
          borderRadius: "4px",
        },

        "& .editable input": {
          padding: "0px 12px",
        },

        "& .MuiDataGrid-cell--editable .MuiSvgIcon-root.MuiDataGrid-booleanCell": {
          color: palette.secondary.main,
          fontSize: "24px",
        },

        "& .MuiDataGrid-cell--editable .MuiSvgIcon-root": {
          fontSize: "25px",
        },
      },
    },
  },

  MuiTablePagination: {
    styleOverrides: {
      root: {
        "& .MuiTablePagination-toolbar": {
          fontSize: "14px",
          fontWeight: 400,
        },
      },
    },
  },

  MuiDrawer: {
    styleOverrides: {
      root: {
        ".MuiList-root": {
          height: "inherit",
        },
      },
    },
  },

  //  Стилизация контейнера Toolbar //
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: "50px !important",
      },
    },
  },

  // Tabs //
  MuiTab: {
    styleOverrides: {
      root: {
        "&.MuiTab-root": {
          boxShadow: "inset 0px -.5px 0px rgba(255, 255, 255, 0.63)",
        },

        "&.Mui-selected": {
          color: palette.secondary.main,
          background: "linear-gradient(90deg, rgba(136, 136, 136, 0.1) 0%, rgba(147, 147, 147, 0.1) 100.83%)",
        },
      },
    },
  },

  // Button //
  MuiButton: {
    styleOverrides: {
      root: {
        "textTransform": "none",
        "lineHeight": "18px",
        "color": palette.secondary.main,

        "&.MuiButton-containedPrimary": {
          "&:hover": {
            backgroundColor: palette.primary.dark,
          },
        },

        "&.MuiButton-outlinedPrimary": {
          "borderColor": palette.primary.main,

          "&:hover": {
            borderColor: "rgba(215, 215, 215, 0.1)",
            backgroundColor: "rgba(215, 215, 215, 0.1)",
          },
        },

        "&.MuiButton-outlinedSecondary": {
          "borderColor": palette.secondary.A500,

          "&:hover": {
            backgroundColor: "rgba(215, 215, 215, 0.1)",
          },

          "&.Mui-disabled": {
            borderColor: palette.secondary.A600,
            color: palette.secondary.A600,
          },
        },
      },
    },
  },

  //   MenuItem
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        fontWeight: 400,
      },
    },
  },

  // alerts //
  MuiAlert: {
    styleOverrides: {
      root: {
        "&.MuiAlert-standard": {
          backgroundColor: "transparent",
        },
      },
    },
  },

  // Стилизация контейнера Paper //
  MuiPaper: {
    variants: [
      {
        props: { variant: "autoHeight" },
        style: {
          minHeight: "auto !important",
          marginBottom: "20px !important",
        },
      },
    ],
    styleOverrides: {
      root: {
        background: palette.background.default,
      },
    },
  },

  // Стилизация текстового поля //
  MuiTextField: {
    styleOverrides: {
      root: {
        "& input": {
          fontSize: "14px",
          padding: "5px 10px",
        },

        ".MuiIconButton-root": {
          "padding": "0 6px",

          "& svg": {
            width: "19px",
            marginTop: "2px",
          },
        },

        "label.Mui-disabled": {
          color: palette.secondary.A400,
        },

        "input.Mui-disabled, textarea.Mui-disabled": {
          textFillColor: palette.secondary.main,
        },

        "::placeholder": {
          fontSize: "14px",
          fontWeight: "500",
          opacity: "0.2",
        },
      },
    },
  },

  MuiSelect: {
    styleOverrides: {
      root: {
        "fontSize": "14px",
        "fontWeight": "500",

        ".MuiSelect-select": {
          paddingLeft: "10px",
        },
      },
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: "16px",
      },
    },
  },

  MuiFormControl: {
    styleOverrides: {
      root: {
        fontSize: "16px",
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: "16px",
      },
    },
  },

  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: palette.primary.dark,
      },
    },
  },

  MuiTypography: {
    styleOverrides: {
      h2: {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "500",
      },
      h3: {
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "14px",
      },
      h4: {
        fontSize: "12px",
        fontWeight: "700",
        letterSpacing: "1px",
      },
      h5: {
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "14px",
      },
      h6: {
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
      },
    },
  },

  // MUI CSS Baseline //
  MuiCssBaseline: {
    styleOverrides: {
      "html, body": {
        height: "100%",
        overflowX: "hidden",
      },

      "& #root": {
        direction: "ltr",
        height: "100%",
        maxHeight: "100vh",
        overflowX: "hidden",
        scrollbarColor: palette.secondary.main,
        scrollbarWidth: "thin",
      },

      // Scroll styles of selects and popups //
      //   ".MuiModal-root.MuiPopover-root.MuiMenu-root .MuiPaper-root.MuiPaper-elevation": {
      //     "background": "rgba(20, 20, 20, 0.8) !important",
      //     "backdropFilter": "blur(2px)",
      //     "overflow": "hidden",

      //     "& ul": {
      //       "background": "transparent",
      //       "boxShadow": "inset 1px 0 0 #2F2F2F",
      //       "direction": "ltr",
      //       "maxHeight": "300px",
      //       "overflowY": "auto",
      //       "scrollbarColor": "#d4aa70",
      //       "scrollbarWidth": "thin",

      //       ".Mui-selected": {
      //         "backgroundColor": palette.secondary.A600,
      //         "boxShadow": "inset 0px -1px 0px #FFFFFF",

      //         "&:hover, &:focus": {
      //           backgroundColor: palette.secondary.A600,
      //         },
      //       },
      //     },
      //   },

      // Global styles of links
      "a": {
        textDecoration: "none",
      },

      // Datepicker styles
      ".MuiDateRangePicker > div:first-of-type": {
        border: "2px solid rgba(255, 255, 255, 0.12)",
      },

      // Global scrolling styles
      "&::-webkit-scrollbar": {
        width: "6px",
      },

      "&::-webkit-scrollbar-track": {
        borderRadius: "50px",
        background: "rgba(20, 20, 20, 1)",
        boxShadow: "inset 1px 0px 0px #2F2F2F",
        overflow: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: palette.secondary.A50,
        borderRadius: "10px",
        boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      },
    },
  },
}
